import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footerWrapper">
        <span> &copy; 2021 freedailyiptvlists.com</span>
      </div>
    </div>
  );
};

export default Footer;
